import Vue from 'vue'
import App from './App.vue'
import _ from 'lodash'
import store from './store'
import router from './router'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '../theme/index.css'

import Vant from 'vant';
import 'vant/lib/index.css';

import '@/permission' 
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype._ = _; //设置lodash
Vue.use(Vant);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
