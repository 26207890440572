import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data
  })
}

export function infoapi() {
  return request({
    url: '/api/user/info',
    method: 'get',
  })
}
export function getcode() {
  return request({
    url: '/api/user/getcode',
    method: 'get',
  })
}

export function sendcode(data) {
  return request({
    url: '/api/user/sendcode',
    method: 'post',
    data
  })
}

export function registerapi(data) {
  return request({
    url: '/api/user/register',
    method: 'post',
    data
  })
}

export function findpassapi(data) {
  return request({
    url: '/api/user/findpass',
    method: 'post',
    data
  })
}

// 答题获取积分
export function getintegral(data) {
  return request({
    url: '/api/user/getintegral',
    method: 'post',
    data
  })
}
// 记录答题
export function passrate(data) {
  return request({
    url: '/api/user/passrate',
    method: 'post',
    data
  })
}
