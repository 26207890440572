import Vue from 'vue';
import Router from 'vue-router';
const index = () => import('@/page/index/index.vue')
const page2 = () => import('@/page/page2/page2.vue')
const page3 = () => import('@/page/page3/page3.vue')
const page4 = () => import('@/page/page4/page4.vue')
const page5 = () => import('@/page/page5/page5.vue')
const page6 = () => import('@/page/page6/page6.vue')
const zklist = () => import('@/page/zhiku/list.vue')
const zkinfo = () => import('@/page/zhiku/info.vue')
Vue.use(Router);


const routes = [
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录',
        },
        component: () => import('@/page/login/index'),
    },
    {
        path: '/',
        name: 'index',
        meta: {
            title: '首页',
        },
        component: index
    },
    {
        path: '/page2',
        name: 'page2',
        meta: {
            title: '首页',
        },
        component: page2
    },
    {
        path: '/page3',
        name: 'page3',
        meta: {
            title: '首页',
        },
        component: page3
    },
    {
        path: '/page4',
        name: 'page4',
        meta: {
            title: '首页',
        },
        component: page4
    },

    {
        path: '/page5',
        name: 'page5',
        meta: {
            title: '首页',
        },
        component: page5
    },
    {
        path: '/page6',
        name: 'page6',
        meta: {
            title: '首页',
        },
        component: page6
    },
    {
        path: '/zklist',
        name: 'zklist',
        meta: {
            title: '首页',
        },
        component: zklist
    },
    {
        path: '/zkinfo',
        name: 'zkinfo',
        meta: {
            title: '首页',
        },
        component: zkinfo
    },
    {
        path: '/Information',
        name: 'Information',
        meta: {
            title: 'AI资讯',
        },
        component: () => import('@/page/Information/index'),
    },
    {
        path: '/Information/:id',
        name: 'Informationinfo',
        meta: {
            title: 'AI资讯',
        },
        component: () => import('@/page/Information/info'),
    },
    {
        path: '/ask',
        name: 'ask',
        meta: {
            title: '专家咨询',
        },
        component: () => import('@/page/ask/index'),
    },
    {
        path: '/my',
        name: 'my',
        meta: {
            title: '定制空间',
        },
        component: () => import('@/page/my/index'),
    },
    {
        path: '/my/prescription',
        name: 'prescription',
        meta: {
            title: '我的处方',
        },
        component: () => import('@/page/my/prescription'),
    },
    {
        path: '/my/ask',
        name: 'myask',
        meta: {
            title: '我的咨询',
        },
        component: () => import('@/page/my/myask'),
    },
    {
        path: '/my/ask/info',
        name: 'myaskinfo',
        meta: {
            title: '我的咨询',
        },
        component: () => import('@/page/my/myaskinfo'),
    },
]


const mrouter = new Router({
    // mode: 'history',
    routes,
});

export default mrouter
