import { login as loginapi, infoapi } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import md5 from 'js-md5'

const getDefaultState = () => {
    return {
        token: getToken(),
        name: '',
        avatar: '',
        integral: 0,
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_INTEGRAL: (state, integral) => {
        state.integral = integral
    }
}

const actions = {
    // user login
    login({ commit }, userInfo) {
        console.log(userInfo);
        const { username, password } = userInfo
        return new Promise((resolve, reject) => {
            loginapi({ username: username.trim(), password: md5(password) }).then(response => {
                const { data } = response
                commit('SET_TOKEN', data.token)
                setToken(data.token)
                resolve()
            }).catch(error => {
                console.log(error);
                reject(error)
            })
        })
    },

    // get user info
    getInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            infoapi().then(response => {
                const { data } = response
                if (!data) {
                    return reject('Verification failed, please Login again.')
                }
                const { username, avatar, name, integral } = data
                commit('SET_NAME', name)
                commit('SET_AVATAR', avatar)
                commit('SET_INTEGRAL', integral)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({ commit, state }) {
        // return new Promise((resolve, reject) => {
        //     logout(state.token).then(() => {
        //         removeToken() // must remove  token  first
        //         resetRouter()
        //         commit('RESET_STATE')
        //         resolve()
        //     }).catch(error => {
        //         reject(error)
        //     })
        // })
    },

    // // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

